@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('http://fonts.cdnfonts.com/css/sofia-pro');

@font-face {
  font-family: 'FuturaStd';
  src: local('FuturaStd'), url('./assets/fonts/FuturaStdBook.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  src: local('Arial'), url('./assets/fonts/ARIAL.TTF') format('ttf');
}

#root {
    position: relative;
    padding-bottom: 200px;
    @apply min-h-screen leading-relaxed selection:bg-primaryYellow selection:bg-opacity-40 selection:text-black;
  }
